<template>
  <div>
    <div class="dis align_center">
      <div class="icon"></div>
      <div class="service" v-if="correct">修改下月服务计划</div>
      <div class="service" v-else>添加下月服务计划</div>
    </div>
    <el-form
      :model="ruleForm"
      status-icon
      ref="ruleForm"
      label-width="266px"
      :label-position="labelPosition"
    >
      <el-form-item label="填报人" prop="name" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入填报人姓名"
          v-model="ruleForm.name"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="mobile" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入联系电话"
          v-model="ruleForm.mobile"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="服务类型 " required>
        <el-select
          v-model="ruleForm.value"
          placeholder="请选择"
          class="width_500"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="活动名称" prop="activity_name" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入活动名称"
          v-model="ruleForm.activity_name"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="报送月份" prop="submit_time" required>
        <el-date-picker
          class="width_500"
          v-model="ruleForm.submit_time"
          type="month"
          placeholder="选择日期"
          value-format="yyyy-MM"
        >
        </el-date-picker>
      </el-form-item>

      <!-- <el-form-item label="活动时间" prop="time" required>
        <el-date-picker
          v-model="ruleForm.time"
          class="width_500"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          value-format="yyyy-MM"
        ></el-date-picker>
      </el-form-item> -->
      <el-form-item label="活动时间" prop="time" required>
        <el-date-picker
          class="width_500"
          v-model="ruleForm.time"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>

      <!-- <el-form-item label="活动时间" prop="time" required>
        <el-date-picker
          v-model="ruleForm.time"
          type="month"
          placeholder="选择日期"
          value-format="yyyy-MM"
        >
        </el-date-picker>
      </el-form-item> -->

      <el-form-item label="地址" prop="address" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入地址"
          v-model="ruleForm.address"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="主办单位" prop="host_unit" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入主办单位"
          v-model="ruleForm.host_unit"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="承办单位" prop="undertake_unit" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入承办单位"
          v-model="ruleForm.undertake_unit"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系人" prop="contact" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入联系人"
          v-model="ruleForm.contact"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="联系电话" prop="activity_mobile" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入联系电话"
          v-model="ruleForm.activity_mobile"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="活动主题" prop="title" required>
        <el-input
          class="width_500"
          type="text"
          placeholder="请输入活动主题"
          v-model="ruleForm.title"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="典型案例介绍" prop="content" required>
        <div>
          <el-input
            type="textarea"
            :rows="6"
            class="width_500"
            placeholder="请输入典型案例介绍"
            v-model="ruleForm.content"
          >
          </el-input>
        </div>
      </el-form-item>
    </el-form>
    <el-button
      type="primary"
      v-if="correct"
      @click="corrects()"
      style="background-color: #ff6618; border-color: #ff6618"
      >确定修改</el-button
    >
    <el-button
      type="primary"
      v-else
      @click="add()"
      style="background-color: #ff6618; border-color: #ff6618"
      >确定添加</el-button
    >
    <el-button
      type="primary"
      @click="back()"
      style="background-color: #cfcfcf; border-color: #cfcfcf"
      >取消</el-button
    >
  </div>
</template>

<script lang="ts">
export default {
  data() {
    return {
      labelPosition: "left",
      value3: "",
      value2: "",
      textarea: "",
      correct: "",
      options: [],
      ruleForm: {
        time: "",
        name: "",
        mobile: "",
        phone: "",
        activity_name: "",
        host_unit: "",
        undertake_unit: "",
        contact: "",
        activity_mobile: "",
        title: "",
        content: "",
        submit_time: "",
        pro_cate_id: "",
      },
    };
  },
  created() {
    if(this.$route.query.ID){
      this.correct = this.$route.query.ID;
    }
   
    console.log(this.correct);
    this.getcatelist();
  },
  methods: {
    getcatelist() {
      let data = { token: localStorage.eleToken };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        console.log(this.options, "this.options");
      });
    },
    corrects() {
      let data = {
        type: 3,
        id: this.correct,
        token: localStorage.eleToken,
        name: this.ruleForm.name, //填报人
        mobile: this.ruleForm.mobile, //联系电话
        start_time: this.ruleForm.time[0], //时间
        end_time: this.ruleForm.time[1], //时间
        content: this.ruleForm.content, //内容
        activity_name: this.ruleForm.activity_name,
        address: this.ruleForm.address,
        host_unit: this.ruleForm.host_unit,
        undertake_unit: this.ruleForm.undertake_unit,
        contact: this.ruleForm.contact,
        activity_mobile: this.ruleForm.activity_mobile,
        title: this.ruleForm.title,
        submit_time: this.ruleForm.submit_time,
        pro_cate_id: this.ruleForm.pro_cate_id,
      };
      console.log(data);
      this.$post("/monthly_upd", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.$router.push("/nextAction");
      });
    },
    add() {
      let data = {
        type: 3,
        token: localStorage.eleToken,
        name: this.ruleForm.name, //填报人
        mobile: this.ruleForm.mobile, //联系电话
        start_time: this.ruleForm.time[0], //时间
        end_time: this.ruleForm.time[1], //时间
        content: this.ruleForm.content, //内容
        activity_name: this.ruleForm.activity_name,
        address: this.ruleForm.address,
        host_unit: this.ruleForm.host_unit,
        undertake_unit: this.ruleForm.undertake_unit,
        contact: this.ruleForm.contact,
        activity_mobile: this.ruleForm.activity_mobile,
        title: this.ruleForm.title,
        submit_time: this.ruleForm.submit_time,
        pro_cate_id: this.ruleForm.pro_cate_id,
      };
      console.log(data);
      this.$post("/monthly_add", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.$router.push("/nextAction");
      });
    },
    back() {
      this.$router.push("/nextAction");
    },
  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.width_500 {
  width: 500px !important;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}
</style>